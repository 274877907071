import BaseFooter from './base-footer/index.vue'
import BaseUpload from './base-upload/index.vue'
import BaseLoading from './base-loading/index.vue'
import BaseOpenApp from './base-open-app/index.vue'
import BaseOpenWebapp from './base-open-webapp/index.vue'
import BaseSign from './base-sign/sign.vue'

const list = {
    BaseSign,
    BaseFooter,
    BaseUpload,
    BaseLoading,
    BaseOpenApp,
    BaseOpenWebapp
}
export default (app) => {
    Object.keys(list).forEach(key => {
        app.component(key, list[key])
    })
}
<template>
    <div>
        <wx-open-launch-app
            id="launch-btn"
            :appid="appid"
            :extinfo="extinfo"
        >
            <script type="text/wxtag-template">
                <style>.btn { padding: 12px }</style>
                <button class="btn">打开App</button>
            </script>
        </wx-open-launch-app>
    </div>
</template>

<script>
    export default {
        props: {
            appid: {
                type: String,
                default: ''
            },
            extinfo: {
                type: String,
                default: ''
            }
        }
    }
</script>
import base from './base'
import CryptoJS from 'crypto-js'
// import qiniu from 'qiniu-js'
const qiniu = require('qiniu-js')

/**
 * 上传七牛
 * @param {file} file // 对象，上传的文件
 * @param {function(url)} callback // 成功回调 回调参数:url返回七牛url
 * @param {function(err)} error // 错误回调
 * @param {function(progress)} progress // 进度回调 回调参数：progress 进度值
 */
export const uploadQiniu = (file, callback, error, progress) => {
	const url = 'https://cdn-zettayotta-platform-static.medical-force.cn'
	const key = new Date().getTime() + file.name // 文件资源名
	const config = {
		useCdnDomain: true
	}

	const putExtra = {
		fname: file.name,
		params: {},
		mimeType: null
	}
	const observe = {
		next(res) {
			progress && progress(res.total.percent)
		},
		error(err) {
			console.log(err)
			// console.log(err.message)
			// console.log(err.isRequestError)
			// console.log(err.reqId)
			// console.log()

			error && error(err)
		},
		complete(res) {
			// 完成后的操作
			// 上传成功以后会返回key 和 hash  key就是文件名了！
			callback && callback(url + '/' + res.key)
		}
	}

	// 开始上传  token 需要找后端获取(单独的方法)
	// console.log(key)
	const observable = qiniu.upload(file, key, genUpToken(), putExtra, config)
	const subscription = observable.subscribe(observe)
}

const genUpToken = () => {
	const tmp = Math.round(new Date().getTime() / 1000) + 3600
	const putPolicy = {
		scope: 'zetta-yotta-platform',
		deadline: tmp
	}
	const accessKey = '6rtjMUgpbIcctM0wf-FNPuCVZ_YfwVa6BYBNNW4b'
	const secretKey = '106vT7QMI0MBCIESqO6HayUXpxkTRrEp9fWezhy7'

	// SETP 2
	const _putPolicy = JSON.stringify(putPolicy)
	// console && console.log('put_policy = ', put_policy)

	// SETP 3
	const encoded = base.baseEncode(_putPolicy)
	// console && console.log('encoded = ', encoded, CryptoJS.enc)

	// SETP 4
	const hash = CryptoJS.HmacSHA1(encoded, secretKey)
	// let hash = crypto.createHmac('sha1', secretKey).update(encoded).digest('hex')
	const encodedSigned = hash.toString(CryptoJS.enc.Base64)
	// console && console.log('encoded_signed=', encoded_signed)

	const safe64 = function (base64) {
		base64 = base64.replace(/\+/g, '-')
		base64 = base64.replace(/\//g, '_')
		return base64
	}
	// SETP 5
	// let upload_token = accessKey + ':' + safe64(encoded_signed) + ':' + encoded
	// console && console.log('upload_token=', upload_token)
	return accessKey + ':' + safe64(encodedSigned) + ':' + encoded
}

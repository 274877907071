<template>
    <div class="base-footer">
        <div class="base-footer-main"></div>
        <div class="base-footer-main fixed" :style="getStyle">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BaseFooter',
        props: {
            backgroundColor: {
                type: String,
                default: ''
            }
        },
        computed: {
            getStyle () {
                let _s = {}
                if (this.backgroundColor) _s.backgroundColor = this.backgroundColor
                return _s
            }
        }
    }
</script>

import axios from "axios";

export default class BuriedPoint {
	constructor(params = {}) {
		this.data.header = Object.assign(this.data.header, params);
		this.systemInfo = this.getOsInfo();
		// 接口域名
		this.domain = "";
		// 待发送事件队列
		this.queue = [];
		// 服务器时间差值
		this.diffTime = null;
		//  数据上报的间隔，默认10秒
		this.interval = 1000;
		// 定时器
		this.timer = "";
		// 获取操作系统名称
		this.data.header.os_name = this.systemInfo.name;
		this.data.header.device_model = this.systemInfo.name;
		this.setDomain();
	}

	/**
	 * 上报的数据
	 */
	data = {
		requestId: "",
		user: {
			// 用户在公众的唯一标识
			user_unique_id: "",
			// 用户在此项目的唯一标识
			user_id: "",
			// 用户类型0:游客，1:新用户，2:老用户
			user_type: "",
		},

		header: {
			// 客户端ip
			client_ip: "",
			// AB实验vid
			ab_sdk_version: "",
			// App渠道
			app_channel: "",
			// App名称
			app_name: "",
			// App包名
			app_package: "",
			// App版本
			app_version: "",
			// 设备型号
			device_model: "",
			// 操作系统
			os_name: "",
			// 操作系统版本
			os_version: "",
			// traffic_type: '',
			// 事件公共属性
			custom: "",
			//项目名称
			project_name: "",
		},

		events: [],
	};

	/**
	 * 获取操作系统
	 */
	getOsInfo() {
		const userAgent = navigator.userAgent.toLowerCase();
		let name;
		let version = "Unknown";
		if (userAgent.indexOf("win") > -1) {
			name = "Windows";
			if (userAgent.indexOf("windows nt 5.0") > -1) {
				version = "Windows 2000";
			} else if (
				userAgent.indexOf("windows nt 5.1") > -1 ||
				userAgent.indexOf("windows nt 5.2") > -1
			) {
				version = "Windows XP";
			} else if (userAgent.indexOf("windows nt 6.0") > -1) {
				version = "Windows Vista";
			} else if (
				userAgent.indexOf("windows nt 6.1") > -1 ||
				userAgent.indexOf("windows 7") > -1
			) {
				version = "Windows 7";
			} else if (
				userAgent.indexOf("windows nt 6.2") > -1 ||
				userAgent.indexOf("windows 8") > -1
			) {
				version = "Windows 8";
			} else if (userAgent.indexOf("windows nt 6.3") > -1) {
				version = "Windows 8.1";
			} else if (
				userAgent.indexOf("windows nt 6.2") > -1 ||
				userAgent.indexOf("windows nt 10.0") > -1
			) {
				version = "Windows 10";
			} else {
				version = "Unknown";
			}
		} else if (userAgent.indexOf("iphone") > -1) {
			name = "iOS";
		} else if (userAgent.indexOf("mac") > -1) {
			name = "Mac";
		} else if (
			userAgent.indexOf("x11") > -1 ||
			userAgent.indexOf("unix") > -1 ||
			userAgent.indexOf("sunname") > -1 ||
			userAgent.indexOf("bsd") > -1
		) {
			name = "Unix";
		} else if (userAgent.indexOf("linux") > -1) {
			if (userAgent.indexOf("android") > -1) {
				name = "Android";
			} else {
				name = "Linux";
			}
		} else {
			name = "Unknown";
		}
		return { name, version };
	}

	/**
	 * 获取服务器时间
	 */
	getServiceTime() {
		return axios.get(`${this.domain}/buried/getDateTime`);
	}

	/**
	 * 上报数据
	 * @param {*} params
	 * @returns
	 */
	async push(params = {}) {
		console.log(this.diffTime);
		if (this.diffTime === null) {
			await this.getServiceTime().then(({ data }) => {
				console.log(data);
				if (data.code === 0) {
					this.diffTime = data.data - new Date().getTime();
				}
			});
		}
		let _params;
		if (Object.keys(params).length > 0) {
			_params = {
				...params,
				path: params.path || window.location.href,
				event_ts: this.getNowMillisecond(),
			};
			// 登录日志和播放
			// 生成唯一requestId, 连接socket
			if (
				params.event_type === "login" ||
				params.event_type === "playing"
			) {
				// 先清空requestId
				this.setRequestId(false);
				// 再重新生成requestId
				this.setRequestId(true);
			}

			if (_params.params) {
				_params.params = JSON.stringify(_params.params);
			}

			this.data.events.push(_params);
		}

		if (this.interval === 0) {
			this.send(params.event_type);
		}
	}

	/**
	 * 和服务器时间校验后获取当前时间
	 * @returns
	 */
	getNowMillisecond() {
		return new Date().getTime() + this.diffTime;
	}

	// 设置requestId
	setRequestId(b) {
		this.data.requestId = b ? this.uuid() : "";
	}

	// 生成uuid
	uuid() {
		const s = [];
		const hexDigits = "0123456789abcdef";
		for (let i = 0; i < 36; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
		s[8] = s[13] = s[18] = s[23] = "-";

		return s.join("");
	}

	// 发送数据请求
	send() {
		const _this = this;
		if (_this.data.events.length === 0) return;
		const data = JSON.parse(JSON.stringify(this.data));

		axios
			.post(`${this.domain}buried/sendMsg`, data)
			.then(({ data }) => {
				if (data.code === 0) {
					this.data.events = [];
				}
			})
			.catch((error) => {
				console.log(error);
			});
		_this.data.events = [];
	}

	/**
	 * 设置上报频率频率
	 */
	setInterval(n) {
		if (typeof n !== "number") {
			return;
		}
		this.interval = n * 1000;
		this.startInterval();
	}

	/**
	 * 开启定时器
	 */
	startInterval() {
		if (this.interval === 0) return;
		if (this.timer) {
			clearInterval(this.timer);
		}
		this.timer = setInterval(() => {
			this.send();
		}, this.interval);
	}

	/**
	 * 设置用户信息
	 */
	setUserInfo(data) {
		if (!(data instanceof Object)) {
			return;
		}

		this.data.user = Object.assign(this.data.user, data);
	}

	/**
	 * 设置header
	 */
	setHeader(data) {
		if (!(data instanceof Object)) {
			return;
		}
		this.data.header = Object.assign(this.data.header, data);
	}

	/**
	 * 设置域名
	 */
	setDomain() {
		this.domain = process.env.VUE_APP_DOMAIN;
	}

	/**
	 * 初始化，并把实例挂载到全局对象上
	 * @returns
	 */
	static init(params) {
		const _this = new this(params);
		_this.startInterval();
		return _this;
	}

	/**
	 * 更改初始化后请求头里的数据
	 */
	updateInitData(data) {
		if (!(data instanceof Object)) {
			return;
		}
		this.data.header = Object.assign(this.data.header, data);
	}
}

export default [
	{
		path: "/meeting/agree/login",
		name: "MeetingAgreeLogin",
		component: () => import("../../views/meeting/agree/login.vue"),
		meta: {
			title: "登录",
		},
	},
  {
		path: "/meeting/agree/preview",
		name: "MeetingAgreePreview",
		component: () => import("../../views/meeting/agree/preview.vue"),
		meta: {
			title: "会议协议签署",
		},
	},
  {
		path: "/meeting/agree/list",
		name: "MeetingAgreeList",
		component: () => import("../../views/meeting/agree/list.vue"),
		meta: {
			title: "会议协议签署",
		},
	},
  {
		path: "/meeting/agree/question",
		name: "MeetingAgreeQuestion",
		component: () => import("../../views/meeting/agree/form/index.vue"),
		meta: {
			title: "问卷调研",
		},
	},
  {
		path: "/meeting/agree/sign",
		name: "MeetingAgreeSign",
		component: () => import("../../views/meeting/agree/sign.vue"),
		meta: {
			title: "请在下方空白处签字，不可连笔，以便后续审核",
		},
	},
  {
		path: "/meeting/agree/content",
		name: "MeetingAgreeContent",
		component: () => import("../../views/meeting/agree/content.vue"),
		meta: {
			title: "会议协议签署",
		},
	},
  {
		path: "/meeting/agree/preview-content",
		name: "MeetingAgreeContent",
		component: () => import("../../views/meeting/agree/preview-content.vue"),
		meta: {
			title: "会议协议预览",
		},
	},
];

import $api from "@/http/api";
export default {
	namespaced: true,
	state: {
		token: null,
		appid: null,
		openid: null,
		unionId: null,
    loginInfo: {}
	},
	getters: {
		getToken: (state) => {
			if (state.token) return state.token;
			return window.sessionStorage.getItem("token");
		},
    getLoginInfo: (state) => {
			if (state.loginInfo.id) return state.loginInfo;
      const loginInfo = window.sessionStorage.getItem("loginInfo");
      if (loginInfo) {
        const _s = JSON.parse(loginInfo);
        if (_s.id) return _s
      }
      return {}
		},
		getAppid: (state) => {
			if (state.appid) return state.appid;
			return (
				window.sessionStorage.getItem("appid") || "wxd881989cd7f2f9f3"
			);
		},
		getOpenid: (state) => {
			if (state.openid) return state.openid;
			return window.sessionStorage.getItem("openid");
		},
	},
	mutations: {
		SET_APPID(state, val = "") {
			state.appid = val;
			window.sessionStorage.setItem("appid", val);
		},
    SET_LOGIN_INFO(state, val = {}) {
			state.loginInfo = val;
			window.sessionStorage.setItem("loginInfo", JSON.stringify(val));
		},
		SET_TOKEN(state, val = "") {
			state.token = val;
			window.sessionStorage.setItem("token", val);
		},
		SET_OPENID(state, val = "") {
			state.openid = val;
			window.sessionStorage.setItem("openid", val);
		},
		SET_UNIONID(state, val = "") {
			state.unionId = val;
			window.sessionStorage.setItem("unionId", val);
		},
	},
	actions: {
		getCustomerServiceInfo({ commit }, code) {
			$api.course.getCustomerServiceInfo({ code }).then((res) => {
				if (res.code === 0) {
					commit("SET_TOKEN", res.data.token);
				}
			});
		},
	},
};

<template>
  <div style="position: relative;">
    <wx-open-launch-weapp
      id="launch-btn"
      username="gh_ebfeec012b89"
      :path="path"
      style="position: absolute; top: 0;left:0; width: 100%;height: 100%; z-index: 100;"
    >
      <script type="text/wxtag-template">
        <style>
          .btn{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.3;
          }
        </style>
        <div class="btn"></div>
      </script>
    </wx-open-launch-weapp>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseOpenApp',
  props: ['path'],
  computed: {
  }
}
</script>

<style scoped>
.cloud-photograph {
  width: 200px;
  padding: 0 10px;
}

.btn {
  width: 200px;
  padding: 10px 0;
  border: none;
  border-radius: 999px;
  background: #1989fa;
  font-size: 16px;
  color: #fff;
}
</style>

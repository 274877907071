export default {
    path: '/i-surveys',
    name: 'iSurveys',
    redirect: '/i-surveys/list',
    component: () => import('../../views/i-surveys/index.vue'),
    meta: {
      title: '加载中...'
    },
    children: [
        {
            path: '/i-surveys/list',
            name: 'iSurveysList',
            component: () => import('../../views/i-surveys/views/list/index.vue'),
            meta: {
              title: '加载中...'
            }
        },
        {
          path: '/i-surveys/form/:id',
          name: 'iSurveysForm',
          component: () => import('../../views/i-surveys/views/form/index.vue'),
          meta: {
            title: '加载中...'
          }
        },
        {
          path: '/i-surveys/result-list',
          name: 'iSurveysResultList',
          component: () => import('../../views/i-surveys/views/result-list/index.vue'),
          meta: {
            title: '历史提交'
          }
      },
      {
        path: '/i-surveys/result',
        name: 'iSurveysResult',
        component: () => import('../../views/i-surveys/views/result/index.vue'),
        meta: {
          title: '加载中...'
        }
      },
      {
        path: '/i-surveys/follow/list/:answerId',
        name: 'iSurveysFollowList',
        component: () => import('../../views/i-surveys/views/follow/list.vue'),
        meta: {
          title: '随访'
        }
      }
    ]
}
export default [
  {
    path: '/landing',
    name: 'landing',
    component: () => import('@/views/base/landing.vue')
  },
  {
    path: '/eMeeting',
    name: 'EMeeting',
    component: () => import('@/views/base/e-meeting.vue')
  },
  {
    path: '/work/detail/:id/:app_name?',
    name: 'workDetail',
    component: () => import('@/views/work/detail.vue')
  },
  {
    // 大麦订单查看作品详情的页面
    path: '/work/dm-detail/:type/:id',
    name: 'workDmDetail',
    component: () => import('@/views/work/dm-detail.vue'),
    meta: { title: '作品详情' }
  },
  {
    path: '/work/i-fenshen-detail/:id/:app_name?',
    name: 'workIFenshenDetail',
    component: () => import('@/views/work/i-fenshen-detail.vue')
  },
  {
    path: '/work/inforDetail/:id',
    name: 'workInforDetail',
    component: () => import('@/views/work/infor-detail.vue')
  },
  {
    path: '/course/detail',
    name: 'courseDetail',
    component: () => import('@/views/course/detail.vue'),
    meta: {
      title: '会议详情'
    }
  },
  // 医阵营服务协议
  {
    path: '/agreement/fw',
    name: 'agreementFw',
    component: () => import('@/views/agreement/fw.vue'),
    meta: {
      title: '服务协议'
    }
  },
  {
    path: '/agreement/ys',
    name: 'agreementYs',
    component: () => import('@/views/agreement/ys.vue'),
    meta: {
      title: '隐私政策'
    }
  },
  // 院外宝服务协议
  {
    path: '/agreement/ywb-fw',
    name: 'agreementYwbFw',
    component: () => import('@/views/agreement/ywb-fw.vue'),
    meta: {
      title: '服务协议'
    }
  },
  {
    path: '/agreement/ywb-ys',
    name: 'agreementYwbYs',
    component: () => import('@/views/agreement/ywb-ys.vue'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/agreement/jin-lian',
    name: 'agreementJL',
    component: () => import('@/views/agreement/jin-lian.vue'),
    meta: {
      title: '用户授权协议'
    }
  },
  {
    path: '/agreement/jin-lian-private',
    name: 'agreementJLYS',
    component: () => import('@/views/agreement/jin-lian-private.vue'),
    meta: {
      title: '用户隐私协议'
    }
  },
  {
    path: '/app/download',
    name: 'appDownload',
    component: () => import('@/views/app/download.vue'),
    meta: {
      title: '医阵营--医生综合服务平台'
    }
  },
  {
    path: '/agreement/ys',
    name: 'agreementYs',
    component: () => import('@/views/agreement/ys.vue'),
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/app/download',
    name: 'appDownload',
    component: () => import('@/views/app/download.vue'),
    meta: {
      title: '医阵营--医生综合服务平台'
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test.vue'),
    meta: {
      title: '医阵营--医生综合服务平台'
    }
  },
  {
    path: '/redBraceletH5/index',
    name: 'redBraceletH5',
    component: () => import('@/views/redBraceletH5/index.vue'),
    meta: {
      title: '红手环科普创客大赛'
    }
  },
  {
    path: '/redBraceletH5/rankingList',
    name: 'redBraceletH5',
    component: () => import('@/views/redBraceletH5/rankingList.vue'),
    meta: {
      title: '红手环科普创客大赛'
    }
  },
  {
    path: '/wallets-authorize',
    name: 'wallets-authorize',
    component: () => import('@/views/wallets-authorize/index.vue'),
    meta: {
      title: '数据资产钱包'
    }
  },
  {
    path: '/declared-oasis-explan',
    name: 'declaredOasisExplan',
    component: () => import('@/views/base/declared-oasis-explan.vue'),
    meta: {
      title: '重症肌无力的规范化诊疗'
    }
  },
  {
    path: '/face',
    name: 'face',
    component: () => import('@/views/face/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/preview-img',
    name: 'previewImg',
    component: () => import('@/views/preview-img/preview-img.vue'),
    meta: {
      title: ''
    }
  }
]
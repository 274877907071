// 医阵营
const wxd881989cd7f2f9f3 = {
  primary: '#F4CD13',
  primaryLight: '#eee5fe',
  fileBaseUrl: 'https://cdn-zettayotta-platform-static.medical-force.cn/file_ssl',
  titlePrefix: 'i',
}

// 长颈鹿智慧医学
const wx41c1352ffcf95479 = {
  primary: '#FAC800',
  primaryLight: '#fef8e2',
  fileBaseUrl: 'https://cdn-zettayotta-platform-static.medical-force.cn/file_ssl_cjl',
  titlePrefix: 'E',
}

export default {
  wxd881989cd7f2f9f3,
  wx41c1352ffcf95479
}
<template>
  <div id="base_sign" class="base_sign no_touch h-scrreen">
    <baseSignMain style="height: calc(80vh - 48px);" ref="signature"/>
    <base-footer background-color="#fff">
      <div class="base_sign_footer">
        <van-row :gutter="30">
          <van-col :span="12"><van-button block @click="reset">清除重写</van-button></van-col>
          <van-col :span="12"><van-button block type="primary" @click="clickSubmit">完成签字</van-button></van-col>
        </van-row>
      </div>
    </base-footer>
  </div>
</template>


<script>
import CORSSDK from "cos-js-sdk-v5";
import baseSignMain from './signMain.vue'
let cosConfigResult = {}
export default {
  name: 'BaseSign',
  props: ['type', 'targetTable', 'targetId'],
  components: { baseSignMain },
  data: () => ({
    signature: null,
    visible: false,
    cors: null,
    corsConfig: {
      bucket: '', /* 填入您自己的存储桶，必须字段 */
      region: '',  /* 存储桶所在地域，例如ap-beijing，必须字段 */
      key: 'lpm/', /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
    },
    corsConfigRes: {}
  }),
  mounted () {
    this.getCors()
  },
  methods: {
    reset () {
      this.$refs.signature.reset();
    },
    previous () {
      this.visible = false
      this.$emit('previous');
    },
    getAuthorization (options, callback) {
      if(callback) {
        callback(cosConfigResult)
      }
    },
    getCors() {
      const _this = this
      this.$api.base.getCOSTempSign().then(res => {
        _this.corsConfig.bucket = res.data.bucketName
        _this.corsConfig.region = res.data.region
        _this.corsConfig.key = res.data.prefix || ''
        _this.corsConfigRes = res.data;
        let result = {
          TmpSecretId: res.data.tmpSecretId,
          TmpSecretKey: res.data.tmpSecretKey,
          SecurityToken: res.data.token,
          // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
          StartTime: res.data.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: res.data.expireAt, // 时间戳，单位秒，如：1580000000
        }
        _this.corsConfig = {
          ...result,
          ..._this.corsConfig
        }
        cosConfigResult = result
        _this.cors = new CORSSDK({
          getAuthorization: this.getAuthorization
        })
      })
      
    },
    uuid () {
      var s = []
      var hexDigits = '0123456789abcdefghi'
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = '-'

      var uuid = s.join('')
      return uuid
    },
    base64ToBlob(base64Data) {
      let byteString
      if (base64Data.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(base64Data.split(',')[1])
      } else {
        byteString = unescape(base64Data.split(',')[1])
      }
      const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ia], {
        type: mimeString
      });
    },
    clickSubmit () {
      this.$dialog({
        title: '提示',
        message: '确认提交签字么？',
        showCancelButton: true,
        teleport: '#base_sign'
      }).then(() => {
        // let file = fileRaw.file
        // var fileSuffix = file.name.substr(file.name.lastIndexOf('.')+1, file.name.length)
        if (!this.$refs.signature.isDraw) {
          this.$toast('请先完成签字')
          return
        }
        const file = this.base64ToBlob(this.$refs.signature.getBase64PNG())
        this.cors.putObject({
          Bucket: this.corsConfig.bucket, /* 填入您自己的存储桶，必须字段 */
          Region: this.corsConfig.region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
          Key: this.corsConfig.key + this.uuid() + '.' + 'png',  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
          Body: file,
          onProgress: function(progressData) {
            console.log(progressData)
          }
        }, (err, data) => {
          if(err) {
            this.$toast('上传失败')
            return false
          }
          this.visible = false
          this.$emit("uploadSucc", {
            type: 'sign',
            url: data.Location
          })
          this.getCors()
        })
      }).catch(err => {
        console.log(err)
      })
    },
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.reset()
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.no_touch {
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
}
.base_sign {
  .base_sign_footer {
    width: 80%;
    margin: 0 auto;
  }
  .van-button {
    height: 46px;
    border-radius: 3px;
    &.van-button--default {
      background-color: #EEEEEE;
      border-color: #EEEEEE;
    }
    .van-button__text {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      color: #000000;
    }
  }
}
</style>

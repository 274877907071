<template>
    <div class="base-loading" :class="getClass">
        <van-loading size="24px" type="spinner">加载中...</van-loading>
    </div>
</template>

<script>
    export default {
        props: {
            fixed: Boolean
        },
        computed: {
            getClass () {
                const _class = []
                if (this.fixed) _class.push('fixed')
                return _class
            }
        }
    }
</script>

<style scoped lang="less">
    .base-loading {
        padding: 20px 0;
        width: 100%;
        text-align: center;
        &.fixed {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 10;
        }
    }
</style>

import COS from 'cos-js-sdk-v5'
import http from '../http'
export let cos
let Bucket = ''
let Region = ''
const getCosAuthorization = () => http.post(`${process.env.VUE_APP_BASE_URL}/patient/oss/cpnt/getCosStsToken`)

const initCos = () => {
  cos = new COS({
    getAuthorization: function (options, callback) {
      getCosAuthorization().then(({ data }) => {
        const credentials = data
        callback({
          TmpSecretId: credentials.tmpSecretId,
          TmpSecretKey: credentials.tmpSecretKey,
          SecurityToken: credentials.token,
          // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
          StartTime: credentials.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: credentials.expireAt // 时间戳，单位秒，如：1580000000
        })
      }).catch((err) => {
        console.log(err)
      })
    }
  })
}
const initBucket = () => {
  if (Bucket) {
    return new Promise((resolve, reject) => {
      resolve({ data: { bucketName: Bucket, region: Region } })
    })
  } else {
    return getCosAuthorization()
  }
}

export const uploadCos = (file, start, fnProgress) => {
  if (typeof cos === 'undefined') {
    initCos()
  }
  const date = new Date()
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  const time = `${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`

  const arr = file.name.split('.')
  const fileName = arr[0]
  const suffix = arr[arr.length - 1]

  return new Promise((resolve, reject) => {
    initBucket().then((region) => {
      Bucket = region.data.bucketName
      Region = region.data.region
      cos.uploadFile({
        Bucket: region.data.bucketName,
        Region: region.data.region,
        Key: `yzy-h5/${time}/${fileName}-${new Date().getTime()}.${suffix}`,
        Body: file,
        // eslint-disable-next-line space-before-function-paren
        onTaskStart () {
          if (typeof start === 'function') {
            start()
          }
        },
        onProgress: function (info) {
          var percent = parseInt(info.percent * 10000) / 100
          var speed = parseInt(info.speed / 1024 / 1024 * 100) / 100
          if (typeof fnProgress === 'function') {
            fnProgress({ percent, speed })
          }
        }
      }, (err, data) => {
        if (err) {
          this.$toast('上传失败')
          reject(err)
        } else {
          resolve(`https://${data.Location}`)
        }
      })
    })
  })
}

<template>
  <div :class="$store.getters['user/getAppid']">
    <transition name="van-fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
export default {
	mounted() {
		this.wxConfig();
	},
	methods: {
		wxConfig() {
			this.$api.base
				.wxConfig({
					appId: this.$store.getters["user/getAppid"] || 'wx0ee039fdcca3c3b5',
					url: window.location.href.split("#")[0],
				})
				// jsApiList: ["chooseWXPay"],
				.then((res) => {
					if (!res.code) {
						wx.config({
							debug: false,
							appId: res.data.appId, // 必填，公众号的唯一标识
							timestamp: res.data.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
							signature: res.data.signature, // 必填，签名
							jsApiList: ["chooseWXPay", "‘chooseImage", "previewImage"],
							openTagList: ["wx-open-launch-weapp"],
						});

						wx.ready(() => {
							document.addEventListener('WeixinOpenTagsError', function (e) {
								console.error('=========='); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
								console.error(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
							});
						})
					}
				});
		},
	},
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	to.query.code &&
		store.dispatch("user/getCustomerServiceInfo", to.query.code);
	to.query.appid && store.commit("user/SET_APPID", to.query.appid);
	to.query.token && store.commit("user/SET_TOKEN", to.query.token);
	to.query.openid && store.commit("user/SET_OPENID", to.query.openid);
	to.query.unionId && store.commit("user/SET_UNIONID", to.query.unionId);
	to.meta.title && (document.title = to.meta.title);
  to.query.pageTitle && (document.title = to.query.pageTitle);

	next();
});

export default router;

export default {
    windowClose: function () {
        setTimeout(function () {
            document.addEventListener(
                "WeixinJSBridgeReady",
                function () {
                    WeixinJSBridge.call("closeWindow");
                },
                false
            );
            //这个可以关闭ios系统的手机
            WeixinJSBridge.call("closeWindow");
        }, 300);
    },
   
    phoneType: function () {
        //Android终端
        let isAndroid = /(Android)/i.test(navigator.userAgent);
        //Ios终端
        let isiOS = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
        return {
            isAndroid: isAndroid,
            isiOS: isiOS
        }
    },

    wxPay (params) {
        return new Promise((resolve, reject) => {
            wx.chooseWXPay({
                timestamp: params.timeStamp || params.timestamp,
                nonceStr: params.nonceStr,
                package: params.packageValue || params.package,
                signType: params.signType,
                paySign: params.paySign, // 支付签名
                success: resolve
            });
        })
    }
}

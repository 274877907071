export default {
	namespaced: true,
	state: {
		pageTitle: "",
    surveysTitle: ""
	},
	mutations: {
		SET_PAGE_TITLE(state, val = "") {
			state.pageTitle = val;
			document.title = val;
		},
    SET_SURVEYS_TITLE(state, val = "") {
      state.surveysTitle = val;
    }
	},
	actions: {},
};

import axios from 'axios'
import router from '../router'
import store from '../store'
import { Toast, Dialog } from 'vant'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

// Toast.allowMultiple();
let loadingToast = null
axios.interceptors.request.use(config => {
  loadingToast && loadingToast.clear();
	config.headers.token = store.getters['user/getToken']
	config.headers.openid = window.localStorage.getItem('openid')
	if (!config.url.includes(process.env.VUE_APP_DOMAIN)) {
		loadingToast = Toast.loading({
			message: '加载中...',
			forbidClick: true,
			loadingType: 'spinner',
			duration: 0
		})
	}
	return config
}, error => {
	loadingToast && loadingToast.clear()
	Toast('网络超时，请检查网络情况')
	return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  loadingToast && loadingToast.clear();
	if (response.config.url.endsWith('/policy/saveVideoLog')) {
		return response
	}
	if (response.data.code == '10678') {
    Dialog.alert({
      title: '提示',
      message: `您输入的内容中，包含敏感词“${response.data.msg}”无法提交，请修改后重新提交`,
      confirmButtonText: '知道了'
    })
  } else if (response.data.code) {
		Toast(response.data.msg)
	}
	
	return response
}, error => {
	loadingToast && loadingToast.clear()
	Toast('请求超时，请稍后重试')
	return Promise.reject(error)
})

export default {
	get (url, params) {
		return new Promise((resolve, reject) => {
			axios.get(url, { params })
				.then(res => resolve(res.data))
				.catch(err => reject(err))
		})
	},
	post (url, data) {
		return new Promise((resolve, reject) => {
			axios.post(url, data)
				.then(res => resolve(res.data))
				.catch(err => reject(err))
		})
	},
	put (url, data) {
		return new Promise((resolve, reject) => {
			axios.put(url, data)
				.then(res => resolve(res.data))
				.catch(err => reject(err))
		})
	}
}
